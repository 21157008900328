const BROWSER = typeof window !== 'undefined';

import { getClickId } from '$lib/PageSections/Forms/formUtils';

export function getGclid(url) {
	if (!BROWSER || !url) {
		return;
	}

	const gclid = url.searchParams?.get('gclid');
	const gclsrc = url.searchParams?.get('gclsrc');
	const isGclsrcValid = !gclsrc || gclsrc.includes('aw');
	let gclidRecord;
	if (gclid && isGclsrcValid) {
		gclidRecord = createExpiryRecord(gclid);
		try {
			localStorage.setItem('gclid', JSON.stringify(gclidRecord));
		} catch (e) {
			console.error(e);
		}
	}
	if (!gclidRecord) {
		try {
			gclidRecord = JSON.parse(localStorage.getItem('gclid'));
		} catch (e) {
			console.error(e);
		}
	}
	if (gclidRecord && new Date().getTime() < gclidRecord?.expiryDate) {
		return gclidRecord?.value;
	}
	return '';
}

function createExpiryRecord(value) {
	const expiryPeriodMillisec = 90 * 24 * 60 * 60 * 1000;
	const expiryDate = new Date().getTime() + expiryPeriodMillisec;
	return {
		value: value,
		expiryDate: expiryDate,
	};
}

export function getGbraidClickId(url) {
	return getClickId(url, 'gbraid');
}

export function getWbraidClickId(url) {
	return getClickId(url, 'wbraid');
}
